import anatrx from "../Images/Lots_logo.png";
 
import { InputGroup, Form, Col, Card, Button } from "react-bootstrap";
 import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { UseFormValidations, useFormValidation } from "../Validations/useFormValidations"; 

function Login() {
  const navigate = useNavigate();
  const [type, setType] = useState("password");

  const { data, errors, handleSubmit, formChange } = useFormValidation({
    validationSchema: {},
    submit: {},
  });
  
  const submit = () => {
    if (data?.email === "admin@lionorbit.com" && data?.password === "admin@123") {
        navigate("/home");
    } else {
        alert("Incorrect credentials");
    }
};
  

  return (
    <section className="vh-100 background-image">
      <div className="container-fluid h-custom">
        <div className="d-flex  ">
          <div className="col-md-4"></div>
          <div className="col-md-4"></div>
          <div className="col-md-4">
            <img src={anatrx} width={150} height={100} className="" />
          </div>
        </div>

        <div className=" d-flex justify-content-between">
          <Col lg={1}> </Col>
          <Col lg={4} xs={12} md={4} className="  me-5">
            <Card className="transparent-card border border-0">
              <Card.Body className="transparent-card mb-1">
                <div className="text-start p-4">
                  <h1 className="text-white"> Welcome Back </h1>
                  <h5 className="text-white">Login to your Account</h5>
                </div>

                <Form id="login-form" className="p-4 background-shade">
                  <Form.Group className="mb-3">
                    <InputGroup hasValidation>
                      <Form.Control size="lg" type="email" required id="email" name="email" className="text-white input-bottom-border  password  " placeholder="Enter User Name" onChange={formChange("")} />
                    </InputGroup>
                  </Form.Group>

                  <Form.Group className="mb-3">
                    <InputGroup hasValidation>
                      <Form.Control size="lg" type={type} name="password" required placeholder="Enter Password " className="text-white input-bottom-border password  " onChange={formChange("")} />
                    </InputGroup>
                  </Form.Group>
                  <Form.Group className="text-end">
                    <Form.Label className="text-white">Forget Password</Form.Label>
                  </Form.Group>

                  <div className="text-center">
                    <Button onClick={() => submit()} style={{ backgroundColor: "#084969" }} size="lg" className="btn-size mt-3 w-100 d-flex border-0  justify-content-center  ">
                      Sign In
                    </Button>
                  </div>
                </Form>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={6} xs={12} md={6}></Col>
        </div>
      </div>
    </section>
  );
}

export default Login;
