import React, { useState, useRef } from "react";
import { Modal as BootstrapModal } from "bootstrap";
import { TiDeleteOutline } from "react-icons/ti";
import camel from "../Images/Lots_logo.png";
import axios from "axios";
import { notify } from "../Utils/Config";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

function HomeNewDatesRange() {
  const [files, setFiles] = useState([]);
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef(null);
  const navigate = useNavigate();

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleFileChange = async (e) => {
    const newFiles = await Promise.all(
      Array.from(e.target.files).map(async (file) => {
        const base64 = await fileToBase64(file);
        return {
          id: Date.now() + Math.random(),
          logo: URL.createObjectURL(file),
          base64: base64,
        };
      })
    );

    setImages([...images, ...newFiles]);
  };

  const handleImageClick = (image) => {
    setSelectedImage(image);
    const modalElement = document.getElementById("imageModal");
    const modalInstance = new BootstrapModal(modalElement);
    modalInstance.show();
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  const handleDeleteImage = (imageId) => {
    setImages(images.filter((image) => image.id !== imageId));
  };

  const Submit = async () => {
    const data = images.map(({ id, base64 }) => ({ id, base64 }));
    setIsLoading(true);
    try {
      const res = await axios.post("https://baithna.tribandtech.com:8021/Pneumonia_classfier", data);
      if (res?.data?.length !== 0) {
        setFiles(res?.data);
        notify(true, "Data fetched successfully");
        fileInputRef.current.value = "";
      } else {
        notify(false, "Something went wrong");
      }
      console.log(res?.data, "response from db");
    } catch (error) {
      notify(false, "Error occurred during submission");
      console.error("Error in submission:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const labelBgColors = (type) => {
    switch (type) {
      case "Need More Information":
        return "#FC2048";
      case "Normal":
        return "#44d400";
      case "Pneumonia":
        return "#d48000";
      default:
        return "#000000";
    }
  };

  const ClearAll = () => {
    setImages([]);
    setFiles([]);
    fileInputRef.current.value = "";
  };

  const Loader = () => (
    <div className="loader-overlay">
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );

  return (
    <div className="App">
      {isLoading && <Loader />}  
      <header className="d-flex justify-content-between align-items-center border p-1">
        <div className="col-md-4 text-start">
          <img src={camel} style={{ width: "50px", height: "50px" }} alt="Logo" className="logo ms-5" onClick={() => navigate("/")} />
        </div>
        <div className="col-md-6 text-start"></div>
        <div className="col-md-2 d-flex justify-content-center text-center">
          <h5 className="p-2 text-white" style={{ backgroundColor: "#084969", width: "350px", height: "50px" }}>
            Pneumonia Predictor
          </h5>
        </div>
      </header>

      <div className="d-flex justify-content-between mb-3 mt-1">
        <div className="col-md-6 d-flex justify-content-center">
          <div className="col-md-6 text-start">
            <label>Upload Images</label>
            <br />
            <small className="text-secondary">Single/Multiple image file can be uploaded</small>
            <input
              type="file"
              className="form-control mt-2"
              onChange={handleFileChange}
              multiple
              ref={fileInputRef}
            />
            <div className="col-md-6 d-flex">
              <button style={{ backgroundColor: "#084969", width: "200px" }} className="btn btn-primary mt-5" onClick={Submit}>
                Predict
              </button>
              <button style={{ backgroundColor: "#084969", width: "200px" }} className="btn btn-primary mt-5 ms-3" onClick={ClearAll}>
                Reset
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <h5>Preview Images</h5>
          <div className="col-md-12 d-flex justify-content-center flex-wrap shadow-sm rounded">
            {images.length !== 0 ? (
              images.map((image) => (
                <div key={image.id} className="col-md-2 gallery-image position-relative" onClick={() => handleImageClick(image)}>
                  <img src={image.logo} style={{ width: "200px", height: "100px" }} alt="Gallery" className="img-thumbnail" />
                  <TiDeleteOutline
                    size={20}
                    className="position-absolute top-0 end-0 text-danger"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteImage(image.id);
                    }}
                  />
                </div>
              ))
            ) : (
              <div className="text-danger">
                <p>No records found</p>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="modal fade" id="imageModal" tabIndex="-1" aria-labelledby="imageModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="imageModalLabel">
                Selected Image {selectedImage && selectedImage.id}
              </h5>
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleCloseModal}></button>
            </div>
            <div className="modal-body">{selectedImage && <img src={selectedImage.logo} alt="Selected" className="img-fluid" />}</div>
          </div>
        </div>
      </div>

      <hr />

      <>
        <h4 className="">Prediction Output</h4>
        <div style={{ maxHeight: "45vh", overflow: "auto" }}>
          {files?.map((e) => (
            <Col lg={12} className="d-flex justify-content-between align-items-center border p-1" key={e.id}>
              <Col lg={4} className="d-flex justify-content-center">
                <img src={e?.base64} alt="Result" className="rounded" style={{ width: "25%", height: "auto" }} />
              </Col>
              <Col lg={4} className="d-flex justify-content-center">
                <button className="p-2 rounded border-0" style={{ backgroundColor: labelBgColors(e?.label), width: "200px",height:"40px", cursor: "auto" }}>
                  {e?.label}
                </button>
              </Col>
            </Col>
          ))}
        </div>
      </>
    </div>
  );
}

export default HomeNewDatesRange;
